.changeRequestListContainer {
    width: 100%;
    margin: 0 auto;
    max-height: calc(100vh - 200px);
    overflow-y: auto;
}

.changeRequestListGroupItem {
    padding: 0.2em;
    border: none;
    border-radius: 5px;
    background-color: #f3f3f3;
}

.changeRequestListGroupItem:hover {
    cursor: pointer;
    border: none;
}

.emptyListPlaceholder {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid black;
    height: 100%;
}