.headerRow {
  margin-bottom: 1rem;
}

.createNewButton {
  float: right;
}

.mottakerEditContainer {
  padding: 1rem;
}

.selectedMottakerContainer {
  display: flex;
  justify-content: space-between;
  align-content: baseline;
}

.mottakerInfoContainer {
  padding-top: 1rem;
}

.spinnerContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid lightgrey;
  height: 920px;
}