.spinnerBtn {
  display: flex;
  justify-content: center;
  align-items: center;
}
.spinner {
  margin-left: 0.5em;
}

.linkToEnhetBtn {
  font-size: 1em;
  padding-left: 0.2em;
}
