.grossistnrColumn {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
}

.kortnavnColumn {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
}

.nedlagtColumn {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
}

.grossistColumn {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
}

.columnHeader {
  padding: 0;
  margin: 0 auto;
}

.sortableColumnHeader {
  padding: 0;
  margin: 0;
}

.goToEnhetLinkBtn {
  float: right;
}

.kortnavnText {
  margin: 0;
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}

.menuCell {
  vertical-align: middle !important;
}

.kortnavnTableCell {
  width: 456px;
}
