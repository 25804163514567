.container {
  height: calc(100vh - 60px);
  width: 100vw;
  overflow-y: hidden;
  overflow-x: hidden;
}

@media (max-width: 1199px) {
  .container {
    overflow-y: scroll;
    overflow-x: hidden;
  }
}

.lopenrHeader {
  width: 100%;
  text-align: center;
}

.headerRow {
  margin: 0 auto;
  padding-top: 1em;
  padding-bottom: 2em;
}

.headerContentCol {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.buttonToolbarRow {
  margin: 0 auto;
  padding-bottom: 2em;
  display: flex;
  justify-content: space-between;
}

.actionButtonGroup {
  width: 100%;
}

.tabContent {
  margin: 0 auto;
}

.buttonCol {
  width: 100%;
  display: flex;
}

.contentRow {
  margin: 0 auto;
  height: 100%;
}

.placeHolderContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.skeletonBox {
  align-self: center;
  width: 44.5px;
  display: inline-block;
  height: 1em;
  overflow: hidden;
  background-color: #4a5057;
  margin-right: 0.2em;
}

.placeholderItem {
  border-radius: 2px;
  height: 24px;
  width: 63.69px;
  position: relative;
  overflow: hidden;
  background-color: #dcdee0;
}

.placeholderItem::before {
  content: '';
  display: block;
  position: absolute;
  left: -150px;
  top: 0;
  height: 100%;
  width: 150px;
  background: linear-gradient(to right, transparent 0%, #4a5057 50%, transparent 100%);
  animation: load 1s cubic-bezier(0.4, 0, 0.2, 1) infinite;
}

@keyframes load {
  from {
    left: -150px;
  }

  to {
    left: 100%;
  }
}

.navigateLopenrIcon {
  cursor: pointer;
  margin-right: 0.2em;
}

.navigateLopenrIcon:hover {
  color: rgba(var(--bs-dark-rgb));
}

.publiserBtnIcon {
  margin-left: 0.3em;
}

.publishBtnDisabled {
  background-color: var(--bs-btn-disabled-bg) !important;
  border-color: var(--bs-btn-disabled-border-color) !important;
  opacity: 0.65 !important;
}