@import '@tradesolution/iceberg-styles/dist/css/iceberg.min.css';
@import 'node_modules/@tradesolution/iceberg-styles/src/scss/variables';

html,
body {
  height: 100%;
  width: 100%;
  background-color: $Background-W000;
}

body {
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: 400;
  padding-top: 55px;
}

/* NAVBAR START */
.navbar {
  font-size: 14px;
  line-height: 20px;
  min-height: 50px;
  background-color: $B700;
  font-weight: 600;
  padding: 0;
}

.navbar .navbar-brand img {
  height: 50px;
  width: 189px;
}

.navbar .navbar-nav .nav-link {
  color: white;
  margin: 0;
  height: 50px;
  display: flex;
  align-items: center;
}

.navbar .navbar-nav .nav-link:hover {
  color: white;
  background-color: $Base-B900;
}

.navbar .navbar-nav .nav-link:focus {
  color: white;
  background-color: $Base-B900;
}

.navbar .navbar-toggler {
  height: 50px;
  border: none;
  padding-top: 0;
  padding-bottom: 0;
}

.navbar-light .navbar-brand {
  display: flex;
  align-items: center;
  color: white;
  height: 50px;
  padding-bottom: 0;
  padding-top: 0;
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(255,255,255, 1)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar .navbar-toggler:focus {
  box-shadow: none;
}

.navbar-collapse .nav-link {
  padding-right: 15px;
  padding-left: 15px;
}

/* For changing out "arrow-down"/"caret" on navn.dropdown (still missing animation and will be updated in own iceberg styles later). */
@import url('https://use.fontawesome.com/releases/v5.0.4/css/all.css');

.dropdown-toggle:after {
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  content: '\f107';
  margin-left: 0.3rem;
  margin-top: 0.05rem;
}

.dropdown-toggle.show:after {
  content: '\f106';
}

.dropdown-toggle {
  display: flex;
}

.dropdown-toggle:after {
  border: none;
}

/* NAVBAR END */

.table-dark>tbody>tr:nth-child(2n + 1)>td,
.table-striped>tbody>tr:nth-child(2n + 1)>th {
  background-color: #333;
  color: #fff;
}

.table-dark>tbody>tr:nth-child(2n + 0)>td,
.table-striped>tbody>tr:nth-child(2n + 0)>th {
  background-color: #666;
  color: #fff;
}

.blue-background {
  background-color: $B700;
}

.logo-container {
  width: 200px;
}

ul>.bootstrap-typeahead.clearfix.open {
  width: 400px;
  margin: 10px;
}

.navbar .avatar {
  --avatar-size: 30px;
  display: inline-block;
  width: var(--avatar-size);
  height: var(--avatar-size);

  line-height: var(--avatar-size);
  border-radius: 50%;

  color: $B700;
  text-align: center;
  background: #fff;
  font: normal normal bold 10px/30px Open Sans;
  letter-spacing: 0px;
  margin-top: -10px;
}

/* MOVE THIS TO THE HOME PAGE */

.product-switcher .icon-container {
  max-width: 32px !important;
}

.product-switcher .product-icon {
  border-radius: 6px;
}

.dropdown-menu .product-switcher {
  min-width: 180px;
}

.product-switcher .dropdown-menu a {
  display: block;
  padding: 5px;
}

.navbar .navbar-nav .show>.nav-link {
  color: white;
}

/* START react-dropdown-tree-select */
.react-dropdown-tree-select .dropdown {
  width: 100%;
  /* overflow-y: scroll; */
}

.react-dropdown-tree-select .dropdown .dropdown-content {
  /* overflow-y: scroll; */
  padding: 0;
  max-height: 800px;
}

.react-dropdown-tree-select .dropdown .infinite-scroll-component {
  max-height: 800px;
}

.react-dropdown-tree-select .dropdown .dropdown-trigger {
  width: 100%;
  padding: 0.1em;
  line-height: 1.5;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.react-dropdown-tree-select .node>label {
  padding-right: 2em;
}

.react-dropdown-tree-select .node.disabled>label {
  cursor: not-allowed !important;
}

.react-dropdown-tree-select .toggle {
  cursor: pointer !important;
}

/* END react-dropdown-tree-select */

/* INFINITE SCROLL */
.infinite-scroll {
  text-align: center;
  padding: 20px;
}

.rst__rowWrapper {
  padding: 0;
}

.rst__rowContents {
  position: relative;
  height: 100%;
  border: none !important;
  border-left: none;
  padding: 0 !important;
  border-radius: 0px;
  border-bottom: none !important;
  min-width: 230px;
  flex: 1 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  box-shadow: none !important;
}

.rst__rowSubtitle {
  opacity: 0;
}

.rst__row .rst__rowSearchMatch .rst__rowSearchFocus {
  outline: --green;
}

.enhet-typeahead .dropdown-item {
  padding: 0.25rem 0.4rem;
}

.enhet-typeahead .enhet-typeahead-options .dropdown-menu label {
  margin-bottom: 0px;
  font-weight: normal;
}

.enhet-typeahead .rct-node-icon {
  display: none;
}

.enhet-typeahead .enhet-search-filter-by-aktive {
  margin-bottom: 0px;
  padding-left: 29px;
  list-style-type: none;
}

.enhet-typeahead .enhet-search-filter-by-aktive input {
  margin-right: 10px;
}

.highlight {
  padding: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  background-color: #f8f9fa;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.reactDropdownMultiPicker .dropdown-trigger {
  border-radius: 0.25rem;
  padding: 5px;
}

.reactDropdownMultiPicker .dropdown-trigger>span:after {
  font-size: 18px;
  font-weight: bold;
  color: #555;
  padding: 1em;
}

.reactDropdownMultiPicker .toggle {
  font-size: 18px;
  font-weight: bold;
  color: #555;
  padding: 5px;
}

.reactDropdownMultiPicker .node-label {
  font-size: 14px;
  font-weight: bold;
  color: #555;
}

.reactDropdownMultiPicker .dropdown .dropdown-trigger.arrow {
  padding: 0.2em;
}

.table {

  vertical-align: middle !important;

  // passer på at bredde av kolonne tilpasses innhold
  tbody td.fitContent {
    width: 1%;
    white-space: nowrap;
  }
}

// fixes
.list-group-item.active {
  color: #0075db;
}

// bootstrap switch button
.komplettBtn {
  width: 140px;
  padding: 1.25rem;
}

.switch-on.btn-sm {
  padding-right: 2rem !important;
  top: 2px !important;
}

.switch-off.btn-sm {
  padding-left: 2rem !important;
  top: 2px !important;
}

.form-control:read-only {
  background-color: #f3f3f3;
  border-color: #dcdcdc;
  opacity: 1;
}

.btn-circle {
  width: 30px;
  height: 30px;
  padding: 6px 0px;
  border-radius: 15px;
  text-align: center;
  font-size: 12px;
  line-height: 1.42857;
}