.addressContainer {
  margin: 0 auto;
  margin-bottom: 2.5em;
}

.inputGroup {
  margin-bottom: 0 !important;
  min-width: 50%;
  width: 60%;
  padding-right: 15px;
}

/* div.inputError {
  border-top: 1px red solid;
  border-bottom: 1px red solid;
  border-right: 1px red solid;
}

div.inputError input.rbt-input-focus {
  border-color: red;
  box-shadow: 0 0 0 0.25rem red;
}

div.inputError input.form-control:focus {
  border-color: red;
  box-shadow: none;
} */

.buttonError {
  border-top: 1px red solid;
  border-bottom: 1px red solid;
  border-left: 1px red solid;
}

.addressOptions {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.optionsContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.detailBtn {
  margin-left: 1em;
  min-width: 110px;
  width: auto;
}

.adresseTooltip > .inner-tooltip {
  max-width: 400px;
}
