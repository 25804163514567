.Wrapper {
  margin: 0 auto;
}

.Spinner {
  animation: rotate 2s linear infinite;
  margin: auto;
  margin-top: 5vh;
  display: block;
  width: 200px;
  height: 200px;
  transition: visibility 0s, opacity 0.5s linear;
}

.path {
  stroke: #206193;
  stroke-linecap: round;
  animation: dash 1.5s ease-in-out infinite;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}
