.container {
    margin-top: 2em;
    flex-wrap: nowrap;
}

.firstCol {
    max-width: 250px;
}

.hovedFilterCheckBox {
    align-items: baseline;
}

.c_totalResult {
    margin: 2em 0;
}

.sortableCol {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.sortableColumnHeader {
    padding: 0;
    margin: 0;
}

.loadingInfiniteScroll {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    margin: 8rem;
}

.initialLoadSpinner {
    width: 100%;
    display: flex;
    margin-top: 15em;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.highlighterWrapper {
    display: flex;
    align-items: baseline;
}

.highlighterWrapper>mark {
    background-color: #bfe0ff;
}

@media screen and (max-width: 1436px) {
    .firstCol {
        display: none;
    }

    .smallScreenSizeFilter {
        display: block;
    }
}

@media screen and (min-width: 1436px) {
    .firstCol {
        display: block;
    }

    .smallScreenSizeFilter {
        display: none;
    }
}