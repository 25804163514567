.statusIcon {
    float: right;
    display: flex;
    width: 40px;
    padding-top: 0.5em;
    align-items: center;
    justify-content: space-between;
}

.loadingListPaceholder {
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 77vh;
    width: 100%;
    overflow-y: auto;
    border: 1px solid lightgray;
    border-radius: 5px;
}
  
.loadingListHeader {
    padding-top: 10em;
}