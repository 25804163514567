.menuIcon {
  cursor: pointer;
}

.popoverContent {
  height: 85px;
}

.contentButtons {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
}
