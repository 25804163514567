.modalBody {
  height: 100%;
}

.tabPaneContainer {
  height: 100%;
}

.modalFooter {
  display: flex;
  justify-content: space-between;
}

.leggNedBtn {
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner {
  margin-left: 0.5em;
}
